<template>
  <div class="modal-overlay" v-if="show" @click="handleOutsideClick">
    <div class="modal" ref="modal">
      <div class="content">
        <div class="title">Manage Cookie Preferences</div>
        <p>Please choose which types of cookies you allow us to use. For details of the cookies we use, please refer to
          our Cookie Policy.

<!--           <a href="" style="text-decoration: underline; color: brown">
            Cookie Policy.
          </a> -->

        </p>

        <div class="preference-section">
          <div class="preference-option">
            <h4>Preference cookies</h4>
            <el-switch v-model="preferences.preference" active-color="#13ce66"></el-switch>
          </div>
          <p>Preference cookies enable a website to remember information that changes the way the website behaves or
            looks, like your preferred language or the region that you are in.</p>
        </div>
        <hr style="border-color: rgba(224, 224, 224, 0.3);">
        <div class="preference-section">
          <div class="preference-option">
            <h4>Statistics cookies</h4>
            <el-switch v-model="preferences.statistics" active-color="#13ce66"></el-switch>
          </div>
          <p>Statistic cookies help website owners to understand how visitors interact with websites by collecting and
            reporting information anonymously.</p>
        </div>
        <hr style="border-color: rgba(224, 224, 224, 0.3)">
        <div class="preference-section">
          <div class="preference-option">
            <h4>Marketing cookies</h4>
            <el-switch v-model="preferences.marketing" active-color="#13ce66"></el-switch>
          </div>
          <p>Marketing cookies are used to track visitors across websites. The intention is to display ads that are
            relevant and engaging for the individual user and thereby more valuable for publishers and third party
            advertisers.</p>
        </div>
        <hr style="border-color: rgba(224, 224, 224, 0.3)">
        <div class="preference-section">
          <div class="preference-option">
            <h4>Necessary cookies</h4>
            <el-switch :value="true" active-color="#13ce66" disabled></el-switch>
          </div>
          <p>Necessary cookies help make a website usable by enabling basic functions like page navigation and access to
            secure areas of the website. The website cannot function properly without these cookies.</p>
        </div>

      </div>

      <div class="bottom">
        <button @click="savePreferences">Save preferences</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['show'],
  data() {
    return {
      preferences: {
        preference: false,
        statistics: false,
        marketing: false,
      },
    };
  },
  methods: {
    savePreferences() {
      this.$emit('save', this.preferences);
    },
    cancel() {
      this.$emit('cancel');
    },
    toggleSection(section) {
      this.$set(this.preferences, section, !this.preferences[section]);
    },
    handleOutsideClick(event) {
      if (this.$refs.modal && !this.$refs.modal.contains(event.target)) {
        this.$emit('cancel');
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@assets/styles/vars.scss";
@import "~@assets/styles/func.scss";

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

}

.modal {
  background: #fff;
  padding: 30px;

  /* max-width: 400px; */
  width: 800px;

  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
  }

  p {
    font-size: 14px;
    text-align: left;
  }

  .preference-section {
    margin: 20px 0;
    text-align: left;

    .preference-option {
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h4 {
        font-weight: normal;
        font-size: 20px;
      }

      el-switch {
        width: 50;
      }
    }
  }

  .bottom {
    text-align: right;

    button {
      padding: 10px;
      border: none;
      background-color: #1F6EE5;
      margin-left: auto;
      color: white;
      width: 200px;
      cursor: pointer;
      vertical-align: middle;
    }
  }

}




@media (max-width: $screen-md-min) {



  .modal-overlay {

    align-items: end;

  }


  .modal {
    padding: px2rem(15px);

    /* max-width: 400px; */
    width: 100%;

    .content {
      height: 400px;
      overflow: auto;
    }

    .title {
      font-size: px2rem(20px);
      margin-bottom: px2rem(5px);
    }

    p {
      font-size: px2rem(14px);
    }

    .preference-section {
      margin: px2rem(10px) 0;

      .preference-option {
        margin-bottom: px2rem(5px);

        el-switch {
          width: px2rem(50px);
        }

        h4 {
          font-weight: normal;
          font-size: px2rem(18px);
        }
      }
    }

    .bottom {
      text-align: center;
      font-size: 0;
      margin-top: px2rem(10px);

      button {

        padding: px2rem(5px);
        font-size: px2rem(16px);
        width: px2rem(150px);

        background-color: transparent;
        border-radius: px2rem(20px);
        color: black;
        border: 2px solid black;
      }
    }

  }

}
</style>