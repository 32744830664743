<template>
    <div class="wrapper">
        <div class="button" @click="goFindGowow" v-if="!isCN">
            <img src="@/assets/imgs/product/ori_icon.png" >
            <span>{{$t("index.找到我们")}}</span>
        </div>
        <div class="button" @click="goShop " v-if="isCN" >
            <img src="@/assets/imgs/product/cart_icon.png"  >
            <span>{{$t("slides.现在下单")}}</span>
        </div>
    </div>
</template>

<script>
import { goto } from "@/utils/goto";
import { mapState } from "vuex";
export default {
    data(){
        return {
            show: false
        }
    },
    methods: {
        goShop(){
            goto("/buy");
        },
        goFindGowow(){
            goto("/find-gowow");
        }
    },
    computed: {
        ...mapState(["lang"]),
        isCN() {
            return this.lang === "zh-CN";
        },
    }

}
</script>

<style lang="scss" scoped >
@import "~@assets/styles/vars.scss";
@import "~@assets/styles/func.scss";
@media(min-width: $screen-md-min){

    .wrapper{
        position: fixed;
        bottom: 30px;
        right: 10px;
        z-index: 99;
        display: flex;
        .button{
            cursor: pointer;

            margin: 0 4px;
            background: rgba(66, 71, 65, 0.6);
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 15px;
            height: 55px;
            border-radius: 55px;
            font-weight: bold;
            img{
                margin-right: 5px;
                width: auto;
                height:30px
            }
            span{
                color: #fff;
                font-size: 24px;
            }
        }
    }
}
@media(max-width: $screen-md-min){
    
    .wrapper{
        position: fixed;
        bottom: px2rem(40px);
        z-index: 99;
        display: flex;
        right: px2rem(30px);
        .button{
            cursor: pointer;
            margin: 0 px2rem(8px);
            background: rgba(66, 71, 65, 0.6);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: px2rem(35px);
            padding: 0 px2rem(5px);
            height: px2rem(35px);
            font-weight: bold;
            width: px2rem(140px) ;
            img{
                margin-right: px2rem(5px);
                width: auto;
                height:px2rem(20px)
            }
            span{
                color: #fff;
                font-size: px2rem(13px) ;
            }
        }
    }
}
    
</style>