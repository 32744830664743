import Vue from 'vue'
import { getLang } from '@service/api'
import i18n from '@/locale'

export const lang = Vue.observable({
    value: ''
});

(async () => {
    try {
        const { lang: value } = await getLang() || {};
        lang.value = value
    } catch (e) {
        lang.value = 'zh-CN'
    }
    // lang.value = 'zh-CN'
    i18n.locale = lang.value
})()

export const isCN = () => {
    return lang.value === 'zh-CN'
}
