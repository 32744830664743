<template>
  <div class="wrap">
    <div class="bgimg">
      <img v-if="product==='Alpha'" :src="isCN ? 'https://ai.modehoist.com/gowow/website/240718/appdownload_bg/alphaCN.png':'https://ai.modehoist.com/gowow/website/240718/appdownload_bg/alphaEN.png' " class="image" />
      <img v-else :src="isCN ? 'https://ai.modehoist.com/gowow/website/240718/appdownload_bg/oriCN.png': 'https://ai.modehoist.com/gowow/website/240718/appdownload_bg/oriEN.png' " class="image" />
    </div>
    <div class="box">
      <div><img src="../../../assets/imgs/starticon.png" width="70vw" /></div>
      <div class="title">GOWOW App</div>
      <div class="content" >
        <p>{{ $t("slides.个性化您的骑行体验，全面访问gowow功能") }}</p>
      </div>
      <a
        class="apple"
        href="itms-apps://itunes.apple.com/app/id6443576078"
        target="_blank"
      >
        <img src="../../../assets/imgs/iphone.png" />
        {{ $t("slides.AppStore下载") }}
      </a>
      <a class="apple" :href="detail.url" target="_blank">
        <img src="../../../assets/imgs/android.png" />
        {{ $t("slides.Android下载") }}
      </a>
    </div>
  </div>
</template>
    

<script>
import { app_update } from "@service/api";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["lang"]),
    isCN() {
      return this.lang === "zh-CN"; 
    },
  },
  data() {
    return {
      detail: {},
    };
  },
  props: {
    product: {
      type: String,
      default: "Ori",
      
    },
  },
  mounted() {
    this.fetchDetail();
  },
  methods: {
    async fetchDetail() {
      try {
        const data = await app_update({
          language: "zh-cn",
          product: "0",
          type: "0",
        });
        this.detail = data[0];
        // console.log(data[0]);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

    
<style lang='scss' scoped>
img {
  vertical-align: top;
}
.wrap {
  position: relative;
  width: 100vw;
}
.box {
  position: absolute;
  bottom: 20%;
  left: 10%;
  width: 100%;

  .title {
    font-family: PingFang-SC-Heavy;
    font-weight: 600;
    font-size: 38px;
    line-height: 70px;
  }
  .content {
    font-family: PingFang-SC-Medium;
    // font-weight: 200;
    font-size: 16px;
    line-height: 25px;
    width: 40%;
  }
  .apple {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 280px;
    height: 60px;
    margin-top: 3vh;
    border: black 1px solid;
    border-radius: 5px;
    font-family: PingFang-SC-Bold;
    font-weight: 600;
    font-size: 20px;
    color: black;
    img {
      width: 50px;
      padding-right: 7px;
    }
  }
}
.bgimg {
  width: 100vw;
  background-color: rgb(230, 223, 214);
  .image {
    width: 100vw;
  }
}
</style>