<template>
  <div>
    <div class="pc-header">
      <div class="brand-icon" @click="onBrandClick">
        <img src="@assets/imgs/logowhite.png" width="84px" />
      </div>
      <div class="nav">
        <div class="nav-left">
          <div :class="['nav-item', { active: curNav1 === '0' }]">
            <span>{{ $t("index.产品") }}</span>
            <div class="second-nav" style="bottom: -80px; height: 90px">
              <div
                :class="['second-nav-item', { active: curNav === '0_1' }]"
                @click.stop="() => onNavClick(0, 1)"
              >
                ORI
              </div>
              <div
                :class="['second-nav-item', { active: curNav === '0_2' }]"
                @click.stop="() => onNavClick(0, 2)"
              >
                Alpha
              </div>
            </div>
          </div>
          <!-- <div

            :class="['nav-item', { active: curNav1 === '1' }]"
            @click="() => onNavClick(1)"
          >
            <span>{{ $t("index.vr看车") }}</span>
            <div class="second-nav">
              <div
                :class="['second-nav-item', { active: curNav === '1_1' }]"
                @click.stop="() => onNavClick(1, 1)"
              >
                {{ $t("lifeStyle.摩友圈") }}
              </div>
              <div
                :class="['second-nav-item', { active: curNav === '1_2' }]"
                @click.stop="() => onNavClick(1, 2)"
              >
                {{ $t("lifeStyle.俱乐部") }}
              </div>
            </div>
          </div> -->
          <div
            :class="['nav-item', { active: curNav1 === '5' }]"
            v-if="isCN"
            @click="() => onNavClick(5)"
          >
            <span>{{ $t("index.好物") }}</span>
          </div>
          <div :class="['nav-item', { active: curNav1 === '6' }]" v-if="false">
            <span>{{ $t("index.社区") }}</span>
            <div class="second-nav" style="bottom: -40px; height: 50px">
              <div
                :class="['second-nav-item', { active: curNav === '6_1' }]"
                @click.stop="() => onNavClick(6, 1)"
              >
                {{ $t("lifeStyle.摩友圈") }}
              </div>
            </div>
          </div>
 
          <div :class="['nav-item', { active: curNav1 === '3' }]" >
            <span>{{ $t("index.关于我们") }}</span>
            <div class="second-nav" style="bottom: -80px; height: 90px">
              <div
                :class="['second-nav-item', { active: curNav === '3_1' }]"
                @click.stop="() => onNavClick(3, 1)"
              >
                {{ $t("index.品牌理念") }}
              </div>
              <div
                :class="['second-nav-item', { active: curNav === '3_2' }]"
                @click.stop="() => onNavClick(3, 2)"
              >
                {{ $t("index.我们的故事") }}
              </div>
              <!-- <div
                :class="['second-nav-item', { active: curNav === '7_2' }]"
                @click.stop="() => onNavClick(7, 2)"
              >
                {{ $t("partner.合作伙伴") }}
              </div>
              <div
                :class="['second-nav-item', { active: curNav === '1_3' }]"
                @click.stop="() => onNavClick(1, 3)"
              >
                {{ $t("lifeStyle.媒体中心") }}
              </div>
              <div
                :class="['second-nav-item', { active: curNav === '1_4' }]"
                @click.stop="() => onNavClick(1, 4)"
              >
                {{ $t("lifeStyle.帮助中心") }}
              </div> -->
            </div>
          </div>
          
          <div 
            :class="['nav-item', { active: curNav1 === '7' }]"
            @click.stop="onNavClick(7)"
            >
            <span>{{$t("index.新闻资讯")}}</span>
          </div>
          <div :class="['nav-item', { active: curNav1 === '2' }]" v-if="isCN">
            <span>{{ $t("index.服务与支持") }}</span>
            <div class="second-nav" style="bottom: -120px; height: 130px">
              <div
                :class="['second-nav-item', { active: curNav === '2_1' }]"
                @click.stop="() => onNavClick(2, 1)"
              >
                {{ $t("index.下载") }}
              </div>
              <div
                :class="['second-nav-item', { active: curNav === '2_2' }]"
                @click.stop="() => onNavClick(2, 2)"
              >
                {{ $t("index.品牌课堂") }}
              </div>
              <div
                :class="['second-nav-item', { active: curNav === '2_3' }]"
                @click.stop="() => onNavClick(2, 3)"
              >
                {{ $t("index.产品说明书") }}
              </div>
            </div>
          </div>
          <div :class="['nav-item', { active: curNav1 === '2' }]" v-if="!isCN">
            <span>{{ $t("index.服务与支持") }}</span>
            <div class="second-nav" style="bottom: -160px; height: 170px">
              <div
                :class="['second-nav-item', { active: curNav === '2_1' }]"
                @click.stop="() => onNavClick(2, 1)"
              >
                {{ $t("index.下载") }}
              </div>
              <div
                :class="['second-nav-item', { active: curNav === '2_2' }]"
                @click.stop="() => onNavClick(2, 2)"
              >
                {{ $t("index.品牌课堂") }}
              </div>
              <div
                :class="['second-nav-item', { active: curNav === '2_3' }]"
                @click.stop="() => onNavClick(2, 3)"
              >
                {{ $t("index.产品说明书") }}
              </div>
              <div
                :class="['second-nav-item', { active: curNav === '2_4' }]"
                @click.stop="() => onNavClick(2, 4)"
              >
                {{ $t("index.常见问题") }}
              </div>
            </div>
          </div>


          <div 
            :class="['nav-item', { active: curNav1 === '10' }]" 
            v-if="!isCN"
            @click.stop="() => onNavClick(10)"
          >
            <span>{{$t("index.找到我们")}}</span>
          </div>
          
        </div>
        <div class="nav-right">
          <!-- <div class="nav-item lang-item" v-if="!isCN">
            <el-select
              size="middle"
              :value="lang"
              @change="onChangeLang"
              style="width: 70px;"
              
            >
              <el-option
                v-for="item in lang_options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                style="padding: 0 10px; height: 30px; line-height: 30px;"
              >

                <div
                  style="
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                >
                 <img
                    :src="
                      require('@assets/imgs/nationalflag/' + item.img + '.svg')
                    "
                    style="width: 20px;"
                  />
                  <span >{{ item.label }}</span>

                </div>
              </el-option>
                <template slot="prefix">
                  <span style="height: 100%;display: flex;align-items: center;">
                    <img
                    v-if="lang"
                    :src="require('@assets/imgs/nationalflag/' + lang_options.find(option => option.value === lang).img+ '.svg')"
                    style="width: 30px; "
                    />
                  </span>
                 
                 
                </template>
              
            </el-select>
          </div> -->
          <div class="nav-item" @click="showPopup" v-if="!isCN">
            <img src="@assets/imgs/global_icon.png" style="width: 30px; height: auto;" >
          </div>
          <el-dialog
            :visible.sync="isPopupVisible"
            width="25%"
            class="selector-dialog"
            append-to-body
            align-center=true
            >
            <template v-slot:title>
              <div class="popup-header">
                <img class="planet-icon" src="@assets/imgs/planet_icon.png">
                <div class="popup-title">
                  {{$t("index.国家") }} & {{$t("index.语言")}}
                </div>
              </div>
            </template>
            <div class="selector-label">
              {{ $t("index.国家") }}:
            </div>
            <div class="selector">
              <el-select style="width: 100%;" v-model="selectedCountry" placeholder="Select a country">
                <el-option
                  v-for="country in country_options"
                  :key="country.value"
                  :label="country.label"
                  :value="country.value">
                </el-option>
              </el-select>
            </div>
            <!-- Language Selector using el-select -->
            <div class="selector-label">
              {{ $t("index.语言") }}:
            </div>
            <div class="selector">
              <el-select style="width: 100%;" v-model="selectedLang" placeholder="Select a language">
                <el-option
                  v-for="language in lang_options"
                  :key="language.value"
                  :label="language.label"
                  :value="language.value">
                </el-option>
              </el-select>
            </div>
            <template v-slot:footer>
              <button class="confirm-button" @click="applyChanges">{{$t("index.确认")}}</button>
            </template>
          </el-dialog>
          <template>
            <div
              v-if="isLogin" 
              :class="['user-item', 'nav-item', { active: curNav1 === '4' }]"
              @click="() => onNavClick(4)"
            >
              <i class="el-icon-user"></i>
            </div>
            <div v-if="!isLogin" class="nav-item" @click="onLogin">
              {{ $t("index.登录") }}
            </div>
          </template>

          <div class="nav-item" v-if="isCN">
            <div class="boxbor" @click="gopartner">
              &nbsp;&nbsp; &nbsp;&nbsp;<span>{{
                $t("index.合作伙伴申请")
              }}</span
              >&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          </div>
          <div class="nav-item contact-item" v-if="!isCN">
            <el-select
              :value="$t('index.联系我们')"
              @change="(value) => onNavClick(9,contact_options.indexOf(value)+1)"
              size="middle"
              style="width: 120px;"
            >
              <el-option
                v-for="item in contact_options"
                :key=item
                :label="$t(`index.${item}`)"
                :value=item
                style="padding: 0 10px;text-align: center;"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <FloatBall/>
    <CookieBanner v-if="!isCN"/>
    <!-- <Modal v-model="show" :title="$t('index.立即申请')">
      <PartForm ref="form" @submit="closePopUp" />
    </Modal> -->
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { goto } from "@utils/goto";
import RouteMap, { activeRoute } from "./RouteMap";
import FloatBall from "../../pages/index/components/FloatBall.vue";
import CookieBanner from '../CookiesSetting/CookieBanner.vue';
// import Modal from "@components/Modal";
// import PartForm from "./PartForm";

export default {
  // components: { Modal, PartForm },
  components: { FloatBall ,CookieBanner},
  computed: {
    ...mapState(["lang", "profile","country"]),
    ...mapGetters(["isLogin"]),
    curNav1() {
      if (/^([^_]*)[_]/.test(this.curNav)) {
        return RegExp.$1;
      }
      return undefined;
    },
    isCN() {
      
      return this.lang === "zh-CN";
    },
  },
  data() {
    return {
      // show: false,
      selectedLang:"",
      selectedCountry:"",
      isPopupVisible: false,
      curNav: activeRoute,
      nowlang: "",
      currentUrl: "",
      lang_options: [
        { value: "en-US", label: "English", img: "United_States" },
        // { value: "en-GB", label: "UK", img: "United_Kingdom" },
        { value: "ru-RU", label: "Russian", img: "Russia" },
        { value: "fr-FR", label: "French", img: "France" },
        { value: "es-ES", label: "Spanish", img: "Spain" },
        { value: "ja-JP", label: "Japanese", img: "Japan" },
        { value: "de-DE", label: "German", img: "Germany" },
        { value: "it-IT", label: "Italian", img: "Italy" },
        
        
      ],
      country_options:[
        {"value": "AU", "label": "Australia"},
        {"value": "AT", "label": "Austria"},
        {"value": "BE", "label": "Belgium"},
        {"value": "BG", "label": "Bulgaria"},
        {"value": "HR", "label": "Croatia"},
        {"value": "CY", "label": "Cyprus"},
        {"value": "CZ", "label": "Czech Republic"},
        {"value": "DK", "label": "Denmark"},
        {"value": "EE", "label": "Estonia"},
        {"value": "FI", "label": "Finland"},
        {"value": "FR", "label": "France"},
        {"value": "DE", "label": "Germany"},
        {"value": "GR", "label": "Greece"},
        {"value": "HU", "label": "Hungary"},
        {"value": "IS", "label": "Iceland"},
        {"value": "IE", "label": "Ireland"},
        {"value": "IT", "label": "Italy"},
        {"value": "JP", "label": "Japan"},
        {"value": "LV", "label": "Latvia"},
        {"value": "LI", "label": "Liechtenstein"},
        {"value": "LT", "label": "Lithuania"},
        {"value": "LU", "label": "Luxembourg"},
        {"value": "MT", "label": "Malta"},
        {"value": "NL", "label": "Netherlands"},
        {"value": "NO", "label": "Norway"},
        {"value": "PL", "label": "Poland"},
        {"value": "PT", "label": "Portugal"},
        {"value": "RO", "label": "Romania"},
        {"value": "RU", "label": "Russia"},
        {"value": "KR", "label": "South Korea"},
        {"value": "ES", "label": "Spain"},
        {"value": "SE", "label": "Sweden"},
        {"value": "CH", "label": "Switzerland"},
        {"value": "GB", "label": "United Kingdom"},
        {"value": "US", "label": "United States"}
      ],
      contact_options: [
      "合作伙伴申请",
      "预约试驾",
      // "故障排除"
      ],
    };
  },
  mounted() {
    //从localStorage获取当前国家,默认国家为US
    this.currentCountry= window.location.hostname + "nowcountry";
    const storedCountry=localStorage.getItem(this.currentCountry);
    if(storedCountry){
      this.selectedCountry=storedCountry;
    }else{
      this.selectedCountry="US";
    }
    //处理语言的相关逻辑
    this.currentUrl = window.location.hostname + "nowlang";
    if (!this.isCN) {
      //每进入一个页面先获取当前存在localStorage的语言字段nowlang
      this.nowlang = localStorage.getItem(this.currentUrl);
      //url参数改变语言存入localStorage
      const langid = window.location.href.split("lang=")[1];
      
      if (langid) {
        localStorage.setItem(this.currentUrl, langid);
        this.nowlang = langid;
        this.setLang(this.nowlang);
        
      }
      // setTimeout(() => {
      //   this.setLang(this.nowlang);
      // }, 300);
    } else {
      localStorage.setItem(this.currentUrl, "zh-CN");
    }
  },
  watch: {
    lang: function () { 
      //将当前网页的语言传给选择框,以显示当前语言
      this.selectedLang = this.lang;
      if (!this.isCN) {
        this.nowlang = localStorage.getItem(this.currentUrl);
        this.setLang(this.nowlang);
        if(!this.nowlang){
          document.documentElement.lang = "en-US";
        }
        else{
          document.documentElement.lang = this.nowlang;
        }
        

      }
    },
  },
  methods: {
    ...mapActions(["setLang","setCountry"]),
    onNavClick(navIdx, nav2Idx = 0) {
      this.curNav = `${navIdx}_${nav2Idx}`;
      const url = RouteMap[this.curNav];
      if (url) {
        goto(url);
      }
    },
    gopartner() {
      goto("/partner");
    },
    onChangeLang(lang) {
      //手动选择器改变语言存入localStorage
      localStorage.setItem(this.currentUrl, lang);
      this.setLang(lang);
    },
    onLogin() {
      goto("/login");
    },
    onBrandClick() {
      goto("/");
    },
    // showPopup() {
    //   this.show = true;
    //   this.$refs.form && this.$refs.form.reset();
    // },
    // closePopUp() {
    //   this.show = false;
    // },
    showPopup() {
      this.isPopupVisible = true; // Show the popup
    },
    closePopup() {
      this.isPopupVisible = false; // Close the popup
    },
    applyChanges() {
      // Handle the selected country and language
      localStorage.setItem(this.currentUrl, this.selectedLang);
      localStorage.setItem(this.currentCountry, this.selectedCountry);
      this.setCountry(this.selectedCountry);
      this.setLang(this.selectedLang);
      this.closePopup(); // Close popup after applying changes
      
    }
  },
};
</script>

<style lang='scss' scoped>
@import "~@assets/styles/vars.scss";
@import "~@assets/styles/mixin.scss";



.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.lang-item ::v-deep {
  .el-input__inner {
    background-color:transparent;
    font-size:0px;
    border: 0;
  }
  .el-select {
    i {
      color: #ffffff;
    }
  }
}
.contact-item ::v-deep{
  .el-input__inner {
    background-color:transparent;
    color: white;
    border: 0;
    padding:0 10px;
    width: 120px;
  }
  .el-select {
    i {
      color: #ffffff;
    }
  }
}


//对话框的样式不能嵌套在.pc-header中
 .selector-dialog ::v-deep {
  .el-dialog__body {
    padding: 10px 15px;
  }
  .popup-header{
    display: flex;
    align-items: center;
    .planet-icon{
      width: 20px; 
      height: auto;
    }
    .popup-title{
      font-size: 17px;
      margin-left: 10px;
      font-weight: bold;

    }
  }
  .selector-label{
    font-size: 12px;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
  .selector {
    margin-bottom: 15px;
  }
  .confirm-button{
    text-transform: uppercase;
    padding: 10px;
    width: 90%;
    background-color: transparent;
    border: solid 1px black;
    margin: 0 auto;
    display: block;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease, color 0.3s ease;
    &:hover {
      background-color: black;
      color: white;
      
    }
  }
}
.pc-header {
  // box-shadow: 0px 0px 12px 0px rgba(215, 215, 215, 0.5);
  position: absolute;
  z-index: 20;
  @include flex-center("row");
  padding-left: 50px;
  padding-right: 28px;
  height: 50px;
  width: 100vw;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.4);
  .brand-icon {
    height: 100%;
    width: 110px;
    object-fit: contain;
    margin-right: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
  }
  .nav {
    flex: 1;
    @extend .flex-between;
    flex-flow: nowrap;
    .nav-item {
      height: 70px;
      display: flex;
      align-items: center;
      line-height: 24px;
      padding: 0 3px;
      margin: 0 3px;
      font-size: 14px;
      position: relative;
      cursor: pointer;
      .second-nav {
        //  display: flex;
        min-width: 120px;
        display: none;
        left: 50%;
        transform: translateX(-50%);
        bottom: -80px;
        position: absolute;
        z-index: 20;
        height: 90px;
        background-color: rgba(0, 0, 0, 0.4);
        // box-shadow: 0px 2px 12px 0px rgba(197, 196, 197, 0.12);
        // justify-content: space-around;
        // align-items: center;
        .second-nav-item {
          text-align: center;
          margin: 0 12px;
          line-height: 40px;
          position: relative;
          &.active::after {
            content: "";
            position: absolute;
            bottom: -4px;
            left: 0;
            right: 0;
            height: 1px;
            background: white;
          }
        }
      }
      &.active::after {
        content: "";
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        border: 1px solid rgba(255, 255, 255, 1);
      }
      &.user-item {
        font-size: 17px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #ffffff;
        line-height: 24px;
        i {
          font-size: 22px;
          margin-right: 3px;
        }
      }
    }
    .nav-left {
      flex-basis: 630px;
      @extend .flex-start;
      white-space: nowrap;
      .nav-item {
        padding: 0 25px;
        &:hover {
          // background: #efefef;
          .second-nav {
            display: block;
          }
        }
      }
    }
    .nav-right {
      @extend .flex-end;
      flex-basis: 280px;
      white-space: nowrap;
      .nav-item {
        margin: 0 15px;
        .boxbor {
          border: 1px white solid;
          line-height: 30px;

        }
      }
      .icon-search {
        width: 28px;
        height: 28px;
      }
    }
  }


 
}
</style>
