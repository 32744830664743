import Vue from 'vue';
import {
  Empty,
  Button,
  Switch,
  Dialog,
  Select,
  Option,
  Form,
  Input,
  FormItem,
  Row,
  Col,
  Cascader,
  pagination,
  Upload,
  DatePicker,
  Radio,
  RadioGroup,
  Tabs,
  TabPane,
  Loading,
  Popover,
  Popconfirm,
  Progress,
  Carousel,
  CarouselItem,
  InputNumber,
  Checkbox, 
  CheckboxGroup
} from 'element-ui';

Vue.use(Progress);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Popover);
Vue.use(Popconfirm);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Empty);
Vue.use(Button);
Vue.use(Switch);
Vue.use(Dialog);
Vue.use(Select);
Vue.use(Option);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Row);
Vue.use(Col);
Vue.use(Cascader);
Vue.use(pagination);
Vue.use(Upload);
Vue.use(DatePicker);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Loading);
Vue.use(InputNumber);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
