import { Toast } from "vant";
import { Message } from 'element-ui'
import platform from './platform'

const info = (message) => {
    if (platform.isPC) {
        return Message.success(message)
    }
    return Toast(message)
}

const success = (message) => {
    if (platform.isPC) {
        return Message.success(message)
    }
    return Toast.success(message)
}

const fail = (message) => {
    if (platform.isPC) {
        return Message.error(message)
    }
    return Toast.fail(message)
}

export default {
    info,
    success,
    fail
}
