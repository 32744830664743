import Vue from 'vue'
const screen_md_min = 992

const setIsPC = () => {
    const cw = document.documentElement.clientWidth
    return cw >= screen_md_min
}

const platform = Vue.observable({
    isPC: setIsPC()
})

window.addEventListener('resize', () => {
    platform.isPC = setIsPC()
})

export default platform