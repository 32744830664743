import {mapState} from 'vuex';
import platform from '@utils/platform';
export default {
  data() {
    return {
      platform,
    };
  },
  computed: {
    ...mapState(['lang']),
    isZhCN() {
      const isCN = this.lang === 'zh-CN';
      return isCN;
    },
  },
};
