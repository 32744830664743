import platform from './platform'
(() => {
    const baseSize = 75;
    function setRem() {
        const cw = document.documentElement.clientWidth
        if (platform.isPC) {
            // pc不设置rem
            document.documentElement.style.fontSize = ''
        } else {
            const scale = cw / 750;
            document.documentElement.style.fontSize = `${baseSize * scale}px`;
        }
    }
    setRem();
    window.addEventListener('resize', setRem)
})();