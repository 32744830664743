import axios from '@utils/axios';
import _ from 'lodash';
const SUCESESS_CODE = [0];

// 解析结果
const resolve = fetchFn =>
  _.wrap(fetchFn, async (fetchFn, params) => {
    const res = await fetchFn(params);
    const { code, msg, data, message } = res || {};
    if (SUCESESS_CODE.includes(+code)) {
      return data;
    }
    throw msg || message;
  });

// 摩友圈
export const circle_list = resolve(params => {
  const url = '/api/circle/list';
  return axios.get(url, { params });
});

export const circle_detail = resolve(params => {
  const url = '/api/circle/detail';
  return axios.get(url, { params });
});

export const circle_like = resolve(data => {
  const url = '/api/circle/like/add';
  return axios.post(url, data);
});

export const circle_unlike = resolve(data => {
  const url = '/api/circle/like/remove';
  return axios.post(url, data);
});

export const circle_comment = resolve(params => {
  const url = '/api/circle/comment';
  return axios.get(url, { params });
});

export const circle_comment_add = resolve(data => {
  const url = '/api/circle/comment/add';
  return axios.post(url, data);
});

export const circle_post = resolve(data => {
  const url = '/api/circle/post';
  return axios.post(url, data);
});

export const circle_myPost = resolve(params => {
  const url = '/api/circle/myPost';
  return axios.get(url, { params });
});

export const circle_delete = resolve(data => {
  const url = '/api/circle/circle/delete';
  return axios.post(url, data);
});

export const circle_comment_delete = resolve(data => {
  const url = '/api/circle/comment/delete';
  return axios.post(url, data);
});

export const circle_report = resolve(data => {
  const url = '/api/circle/report';
  return axios.post(url, data);
});