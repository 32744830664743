import axios from '@utils/axios';
import _ from 'lodash';
const SUCESESS_CODE = [0];
const isDev = process.env.NODE_ENV === 'development';

export * from './api2'

// 解析结果
export const resolve = fetchFn =>
  _.wrap(fetchFn, async (fetchFn, params) => {
    const res = await fetchFn(params);
    const { code, msg, data, message } = res || {};
    if (SUCESESS_CODE.includes(+code)) {
      return data;
    }
    throw msg || message;
  });

export const feedbackAdd = resolve(data => {
  const url = '/api/web/front/feedback/add';
  return axios.post(url, data);
});
export const feedbackAdd2 = resolve(data => {
  const url = '/api/web/front/feedback2/add';
  return axios.post(url, data);
});

export const partnerAdd = resolve(data => {
  const url = '/api/web/front/partner/add';
  return axios.post(url, data);
});

export const getLang = resolve(params => {
  const url = '/api/common/getLang';
  return axios.get(url, { params });
});
/**
 * data: {password , username}
 */
export const login = resolve(data => {
  const url = '/api/user/login';
  return axios.post(url, data);
});

export const logout = resolve(data => {
  const url = '/api/user/logout';
  return axios.post(url, data);
});
/**
 * data: {code , email , password , phonenumber}
 */
export const register = resolve(data => {
  const url = '/api/user/register';
  return axios.post(url, data);
});
/**
 * 修改密码
 * data: {code , email , password , phonenumber}
 */
export const changePassword = resolve(data => {
  const url = '/api/user/password/change';
  return axios.post(url, data);
});
/**
 * res data :{accessToken}
 */
export const refreshToken = resolve(params => {
  const url = '/api/user/refreshToken';
  return axios.get(url, { params });
});

/**
 * 获取验证码 phonenumber ｜ email
 */
export const verificationCode = resolve(data => {
  const url = '/api/user/send/verificationCode';
  return axios.post(url, data);
});

export const check_phonenumber = resolve(data => {
  const url = '/api/user/check/phonenumber';
  return axios.post(url, data);
});

export const check_email = resolve(data => {
  const url = '/api/user/check/email';
  return axios.post(url, data);
});

/**
 * 获取骑手资料
 */
export const getProfile = resolve(params => {
  const url = '/api/user/profile';
  return axios.get(url, { params });
});
/**
 * 获取徽章
 */
export const getMedals = resolve(params => {
  const url = '/api/common/medals';
  return axios.get(url, { params });
});

/**
 * 更新骑手资料
 */
export const updateProfile = resolve(data => {
  const url = '/api/user/profile';
  return axios.post(url, data);
});

/**
 * 查询国家信息列表
 */
export const getCountries = resolve(params => {
  const url = '/api/common/countries';
  return axios.get(url, { params });
});

/**
 * 查询州省信息列表
 */
export const getStates = resolve(params => {
  const url = '/api/common/states';
  return axios.get(url, { params });
});

/**
 * 查询城市信息列表
 */
export const getCities = resolve(params => {
  const url = '/api/common/cities';
  return axios.get(url, { params });
});

/**
 * 网站订单接口-创建
 */
export const order_add = resolve(data => {
  const url = '/api/web/order/add';
  return axios.post(url, data);
});

/**
 * 网站订单接口-详情
 */
// 订单状态 status
// 0=等待支付
// 1=已付款
// 2=已发货
// 4=订单完成
// 8=已取消

// 订单支付状态payStatus
// 0=等待支付
// 1=已付款

// 订单发送状态sendStatus
// 0=未发货
// 1=已发货
// 2=已收货
export const order_detail = resolve(params => {
  const url = '/api/web/order/detail';
  return axios.get(url, { params });
});

/**
 * 网站订单接口-列表
 */
export const order_myList = resolve(params => {
  const url = '/api/web/order/myList';
  return axios.get(url, { params });
});

export const order_cancel = resolve(data => {
  const url = '/api/web/order/cancel';
  return axios.post(url, data);
});

export const order_list = resolve(params => {
  const url = '/api/web/order/myList';
  return axios.get(url, { params });
});

export const order_delete = resolve(params => {
  const url = '/api/web/order/delete';
  return axios.post(url, params);
});
/**
 * 获取所有在售商品信息
 */
export const product_onSale = resolve(params => {
  const url = '/api/web/product/onSale';
  return axios.get(url, { params });
});
/**
 * 获取商品详细信息
 */
export const product_detail = resolve(params => {
  const url = '/api/web/product/detail';
  return axios.get(url, { params });
});
/**
 * 网站全款产品接口
 */
export const product_onFullSale = resolve(params => {
  const url = '/api/web/product/onFullSale';
  return axios.get(url, { params });
});
/**
 * 网站定金产品接口
 */
export const product_onBookingSale = resolve(params => {
  const url = '/api/web/product/onBookingSale';
  return axios.get(url, { params });
});


/**
 * 微信支付接口
 */
export const wepay_createPay = resolve(data => {
  const url = '/api/wepay/createPay';
  return axios.post(url, data);
});

/**
 * PayPal支付接口
 */
export const paypal_createPay = resolve(data => {
  const url = '/api/paypal/createPay';
  return axios.post(url, data);
});
/**
 * 连连支付接口
 */
export const lianlianPay_createPay = resolve(data => {
  const url = '/api/lianlianPay/createPay';
  return axios.post(url, data);
});
//取消支付
export const lianlianPay_queryPayCancel = resolve(params => {
  const url = '/api/lianlianPay/queryPayCancel';
  return axios.get(url, { params });
});
//完成支付查询状态
export const lianlianPay_queryPayStatus = resolve(params => {
  const url = '/api/lianlianPay/queryPayStatus';
  return axios.get(url, { params });
});

// 地址管理
export const address_list = resolve(params => {
  const url = '/api/user/address';
  return axios.get(url, { params });
});

export const address_delete = resolve(data => {
  const url = '/api/user/address/delete';
  return axios.post(url, data);
});

export const address_add = resolve(data => {
  const url = '/api/user/address/add';
  return axios.post(url, data);
});

export const address_update = resolve(data => {
  const url = '/api/user/address/update';
  return axios.post(url, data);
});

export const unbindContactDetails = resolve(data => {
  const url = '/api/user/unbindContactDetails';
  return axios.post(url, data);
});

export const common_upload_url = isDev
  ? 'API/api/common/upload'
  : '/api/common/upload';

/*新闻接口*/
export const front_news_list = resolve(params => {
  const url = '/api/web/front/news';
  return axios.get(url, { params });
});

export const front_news_detail = resolve(params => {
  const url = '/api/web/front/news/detail';
  return axios.get(url, { params });
});

/*课堂接口*/
export const front_class_classType = resolve(params => {
  const url = '/api/web/front/classType';
  return axios.get(url, { params });
});

export const front_class_list = resolve(params => {
  const url = '/api/web/front/class';
  return axios.get(url, { params });
});

export const front_class_detail = resolve(params => {
  const url = '/api/web/front/class/detail';
  return axios.get(url, { params });
});


export const moto_my = resolve(params => {
  const url = '/api/moto/my';
  return axios.get(url, { params });
});

export const moto_info = resolve(params => {
  const url = '/api/moto/info';
  return axios.get(url, { params });
});

export const riding_statistic = resolve(params => {
  const url = '/api/user/riding/statistic';
  return axios.get(url, { params });
});

export const moto_update = resolve(data => {
  const url = '/api/moto/update';
  return axios.post(url, data);
});

export const record_page = resolve(params => {
  const url = '/api/record/page';
  return axios.get(url, { params });
});

export const record_detailMore = resolve(params => {
  const url = '/api/record/detailMore';
  return axios.get(url, { params });
});

export const record_flag = resolve(params => {
  const url = '/api/record/flag';
  return axios.get(url, { params });
});

export const web_front_tags = resolve(params => {
  const url = '/api/web/front/tags';
  return axios.get(url, { params });
});
/**
 * 试驾体验接口
 */
export const testEvent_apply = resolve(data => {
  const url = '/api/web/testEvent/apply';
  return axios.post(url, data);
});
export const testEvent_apply_detail = resolve(params => {
  const url = '/api/web/testEvent/apply/detail';
  return axios.get(url, { params });
});
export const testEvent_detail = resolve(params => {
  const url = '/api/web/testEvent/detail';
  return axios.get(url, { params });
});
export const testEvent_list = resolve(params => {
  const url = '/api/web/testEvent/list';
  return axios.get(url, { params });
});
export const testEvent_aliPayCreate = resolve(data => {
  const url = '/api/web/testEvent/aliPayCreate';
  return axios.post(url, data);
});

export const testEvent_aliPayH5Create = resolve(data => {
  const url = '/api/web/testEvent/aliPayH5Create';
  return axios.post(url, data);
});
export const testEvent_wePayCreate = resolve(data => {
  const url = '/api/web/testEvent/wePayCreate';
  return axios.post(url, data);
});
export const testEvent_wePayH5Create = resolve(data => {
  const url = '/api/web/testEvent/wePayH5Create';
  return axios.post(url, data);
});
/*商城接口*/
export const web_product_onSale = resolve(params => {
  const url = '/api/web/product/onSale';
  return axios.get(url, { params });
});
export const web_product_productCategory = resolve(params => {
  const url = '/api/web/product/productCategory';
  return axios.get(url, { params });
});
export const web_product_detail = resolve(params => {
  const url = '/api/web/product/detail';
  return axios.get(url, { params });
});
/*app更新接口*/
export const app_update = resolve(params => {
  const url = '/api/common/app/update';
  return axios.get(url, { params });
});

/*产品说明书详情接口*/
export const manual_find = resolve(params => {
  const url = '/api/manual/find';
  return axios.get(url, { params });
});

/*产品说明书列表接口*/
export const manual_search = resolve(params => {
  const url = '/api//manual/search';
  return axios.get(url, { params });
});