export function parseQueryString(search=location.search) {
    if (search) {
        return search
            .replace(/^\?/, '')
            .split('&')
            .map(function (pair) {
                return pair.split('=');
            })
            .reduce(function (obj, pair) {
                if (pair[0].trim()) {
                    obj[pair[0]] = pair[1];
                }

                return obj;
            }, {});
    } else {
        return {};
    }
}

export const goto = (url, replace = false, newTab = false) => {
    if (newTab) {
        window.open(url)
        return
    }
    if (replace) {
        location.replace(url)
        return
    }
    location.href = url
}