<template>
    <div v-if="showOverlay" class="cookie-banner-overlay">
    <div v-if="showBanner" >
        <div class="cookie-banner">
            <div >
                <p>
                    We use essential cookies to make our site work. With your consent, we may also use non-essential cookies
                    to improve user experience, personalize content, customize advertisements, and analyze website traffic.
                    For these reasons, we may share your site usage data with our social media, advertising, and analytics
                    partners. By clicking 'Accept all', you agree to our website's cookie use as described in our Cookie Policy.
                    You can change your cookie settings at any time in the Cookie Policy.
<!--                         <a href="" style="text-decoration: underline; color: brown">
                        Cookie Policy.
                        </a> -->
                 
                </p>
             </div>
            <div class="buttons">
                <button class="button button1" @click="openPreferences">Preferences</button>
                <button class="button button2" @click="declineCookies">Decline</button>
                <button class="button button2" @click="acceptCookies">Accept all</button>
            </div>
        </div>

    </div>
    <PreferencesModal :show="showPreferencesModal" @save="setPreferences" @cancel="closePreferences" />

</div>
</template>

<script>
import PreferencesModal from "./PreferencesModel.vue";

export default {
    data() {
        return {
            showBanner: true,
            showPreferencesModal:false,
            showOverlay: true
        };
    },
    mounted() {
        const acceptedCookies = localStorage.getItem('cookiePreferences');
        if (acceptedCookies) {
            this.showBanner = false;
            this.showOverlay = false;
        }
    },
    components: {
        PreferencesModal
    },
    methods: {
        openPreferences() {
            this.showPreferencesModal = true;
            this.showBanner = false;
        },
        closePreferences() {
            this.showPreferencesModal = false;
            this.showBanner = true;
        },
        setPreferences(preferences) {
            localStorage.setItem('cookiePreferences', JSON.stringify(preferences));
            this.showBanner = false;
            this.showPreferencesModal = false;
            this.showOverlay = false;
        },
        declineCookies() {
            const preferences = {
                preference: false,
                statistics: false,
                marketing: false,
            };
            this.setPreferences(preferences);
        },
        acceptCookies() {
            const preferences = {
                preference: true,
                statistics: true,
                marketing: true,
            };
            this.setPreferences(preferences);
        },
    },

}
</script>

<style lang='scss' scoped>
@import "~@assets/styles/vars.scss";
@import "~@assets/styles/func.scss";
.cookie-banner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    pointer-events: all;
    /* Ensures overlay catches all pointer events */
}

.cookie-banner {
    align-items: center;
    background: #fff;
    padding: 40px 20px;
    /* border-radius: 8px; */
    position: absolute;
    bottom: 0;
    left: 0;
    /* Adjusted for better mobile view */
    height: 250px;
    /* Adjusted to fit content */
    display: flex;
    justify-content: center;
    overflow: auto;
    p{
        text-align: left;

    }
}
.buttons{
    display: flex;
    align-items: center; /* Align items vertically center */
    
    
    .button{
        margin-left: 20px;
    
        width: 150px;
        height: 50px;
        &.button1{
            background-color: #fff;
        }
        &.button2{
            color: white;
            background-color: black;
        }
    }
}




@media (max-width: $screen-md-min ) and (min-width: $screen-mobile){
    .cookie-banner {
        justify-content: center;
        display: flex;
        align-items: center; /* Align items vertically center */
        /* Distribute space between items */
        flex-direction: column;
        height: 344px;
        /* Specific height for larger screens */
        font-size: 16px;
        padding: 23px 23px;
    }
    .buttons{
        width: 100%;
        margin-top: 10px;
        justify-content:space-evenly;
        .button{
        border-radius: 50px;
        margin-left: 0;
        width: 30%;
        height: 45px;
    }
    }

}
@media (max-width: $screen-mobile) {
    .cookie-banner {
        justify-content: center;
        display: flex;
        align-items: center; /* Align items vertically center */
        /* Distribute space between items */
        flex-direction: column;
        height: px2rem(300px);
        /* Specific height for larger screens */
        font-size: px2rem(14px);
        padding: px2rem(20px) px2rem(20px);
    }
    .buttons{
        margin-top: px2rem(10px);
        align-items:space-between; 
        .button{
        margin:0 px2rem(5px) 0;
        border-radius: 50px;
        width: px2rem(100px);
        height: px2rem(40px);
    }
    }

}
</style>