const RouteMap = {};
RouteMap['0_1'] = '/product/ORI';
RouteMap['0_2'] = '/product/Alpha';
RouteMap['1_0'] = '/vrlook/ORI';
RouteMap['2_1'] = '/download';
RouteMap['2_2'] = '/brand/class';
RouteMap['2_3'] = '/serve/instructions';
RouteMap['2_4'] = '/faq';
RouteMap['6_1'] = '/motocircle/list';
RouteMap['3_1'] = '/brand/concept';
RouteMap['3_2'] = '/aboutus/electricdirtbike';
RouteMap['4_0'] = '/personal';
RouteMap['5_0'] = '/buy';
RouteMap['8_1'] = '/terms';
RouteMap['8_2'] = '/privacy';
RouteMap['8_3'] = '/shipping';
RouteMap['8_4'] = '/return';
RouteMap['8_5'] = '/contact';
RouteMap['8_6'] = '/about';
RouteMap['8_7'] = '/imprint';
RouteMap['7_0'] = '/brand/news';
RouteMap['9_1'] = '/partner';
RouteMap['9_2'] = '/trial';
RouteMap['9_3'] = '/troubleshooting'
RouteMap['10_0'] = '/find-gowow'
RouteMap['10_1'] = '/press'
// RouteMap['7_2'] = '/partner';

const pathname = location.pathname;
const url = location.href;
const urlMaps = {
  '/product/ORI': '0_1',
  '/product/Alpha': '0_2',
  '/vrlook/ORI': '1_0',
  '/download': '2_1',
  '/brand/class': '2_2',
  '/serve/instructions': '2_3',
  '/faq':'2_4',
  '/motocircle/list': '6_1',
  '/motocircle/detail': '6_1',
  '/motocircle/post': '6_1',
  '/brand/concept': '3_1',
  '/aboutus/electricdirtbike': '3_2',
  '/personal': '4_0',
  '/buy': '5_0',
  '/terms': '8_1',
  '/privacy': '8_2',
  '/shipping': '8_3',
  '/return': '8_4',
  '/contact': '8_5',
  '/about': '8_6',
  '/imprint': '8_7',
  '/brand/news': '7_0',
  // '/partner': '7_2',
  '/partner':'9_1',
  '/trial':'9_2',
  '/troubleshooting':'9_3',
  '/find-gowow':'10_0',
  '/press':'10_1'


};
const matchUrl = () => {
  for (let k in urlMaps) {
    if (url.indexOf(k) > -1) {
      return urlMaps[k];
    }
  }
};



export const activeRoute = matchUrl() || RouteMap[pathname];

export default RouteMap;
